<template>
  <div class="container">
    <content-title :nav="nav"></content-title>

    <div class="contentList">
      <h4 class="sec-title">商户入驻信息列表</h4>

      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="商家名称">
          <el-input
            type="text"
            clearable
            v-model="mechanism_name"
            name="mechanism_name"
            id="name"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status">
            <el-option label="正在审核" value="1"></el-option>
            <el-option label="审核通过" value="2"></el-option>
            <el-option label="审核拒绝" value="3"></el-option>
            <el-option label="注销" value="4"></el-option>
            <el-option label="冻结" value="5"></el-option>
            <el-option label="预留待打电话" value="6"></el-option>
            <el-option label="预留已打电话" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商家手机号">
          <el-input
            type="text"
            clearable
            v-model="contact_telephone"
            name="mechanism_telephone"
            id="tell"
          />
        </el-form-item>

        <el-form-item>
          <el-button @click="search()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="paging">
        <el-table
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :data="recording"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 20px"
          highlight-current-row
          v-loading="is_loading"
        >
          <el-table-column
            prop="id"
            align="center"
            label="id"
          ></el-table-column>
          <el-table-column
            prop="mechanism_name"
            align="center"
            label="商家名称"
          ></el-table-column>
          <el-table-column
            prop="contact_telephone"
            align="center"
            label="联系方式"
          ></el-table-column>
          <el-table-column
            prop="categories"
            align="center"
            label="运动项目"
          ></el-table-column>
          <el-table-column
            prop="mechanism_addr"
            align="center"
            label="地址"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            width="200"
            label="申请时间"
          ></el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: #b8b8b8"
                >正在审核</span
              >
              <span v-else-if="scope.row.status == 2" style="color: #23c3c4"
                >审核通过</span
              >
              <span v-else-if="scope.row.status == 3" style="color: #ff6262"
                >审核拒绝</span
              >
              <span v-else-if="scope.row.status == 4" style="color: #ff6262"
                >注销</span
              >
              <span v-else-if="scope.row.status == 5" style="color: #ff6262"
                >冻结</span
              >
              <span v-else-if="scope.row.status == 6" style="color: #ff6262"
                >预留待打电话</span
              >
              <span v-else-if="scope.row.status == 7" style="color: #b8b8b8"
                >预留已打电话</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="is_recommend"
            align="left"
            label="操作"
          >
            <template slot-scope="scope">
              <div style="display: flex; flex-wrap: wrap">
                <el-button
                  @click="toDetails(scope.row)"
                  size="small"
                  type="success"
                  >编辑</el-button
                >
                <el-button
                  @click="pass(scope.row)"
                  size="small"
                  type="success"
                  v-if="scope.row.status == 1"
                  >通过</el-button
                >
                <el-button
                  @click="refuse(scope.row)"
                  size="small"
                  type="danger"
                  v-if="scope.row.status == 1"
                  >拒绝</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="total"
        ></paging-fy>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-input v-model="context" placeholder="请输入拒绝理由"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="refusesubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: {
        firstNav: "商户管理",
        secondNav: "商户审核",
      },
      mechanism_name: "",
      status: "",
      contact_telephone: "",
      recording: [],
      currentPage: 1,
      total: 0,
      context: "",
      currefues: {},
      is_loading: true,
      dialogVisible: false,
    };
  },
  created() {
    this.GetList();
  },
  methods: {
    GetList() {
      this.is_loading = true;
      let url = "user/mastermechanism/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            // type: 'merchants',
            type: "商户",
            status: this.status !== null ? this.status : 2,
            pageSize: 10,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    search() {
      this.GetList();
    },
    pass(row) {
      let url = "user/mastermechanism/merchantsAudit";
      this.$axios
        .post(url, {
          status: 2,
          id: row.id,
          user_id: row.user_id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "info", message: "审核成功" });
            this.GetList();
          } else {
            this.$message({ type: "info", message: res.data.message });
          }
        });
    },
    refusesubmit() {
      let url = "user/mastermechanism/merchantsAudit";
      this.$axios
        .post(url, {
          status: 3,
          id: this.currefues.id,
          user_id: this.currefues.user_id,
          refuse_contect: this.context,
        })
        .then((res) => {
          this.dialogVisible = false;
          if (res.data.code == 0) {
            this.$message({ type: "info", message: "审核成功" });
            this.GetList();
          } else {
            this.$message({ type: "info", message: res.data.message });
          }
        });
    },
    refuse(row) {
      this.currefues = row;
      this.dialogVisible = true;
    },
    toDetails(row) {
      this.$router.push({
        name: "MerchantDetail",
        query: { id: JSON.stringify(row) },
      });
    },

    handleCurrentChange(v) {
      this.currentPage = v;
      this.GetList();
    },
  },
};
</script>
<style lang="less">
.PERInformation-from > *:not(:last-child) {
  margin-right: 1rem;
}
</style>
